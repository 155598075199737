body {
    background-color: #f9fafc !important;
     
}
iframe{
    height: 0px !important;
    width: 0px !important;
}
.l-app {
    padding-left: 245px;
    background: #f9fafc;
}
.l-sidebar {
    width: 245px;
    height: 100vh;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    &__nav {
        height: calc(100vh - 95px);
        overflow-y: auto;
    }
}
.l-main {
    width: 100%;
    padding: 40px 32px 0 45px;
}

.l-displayNone {
    display: none !important;
}