.c-popup {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    z-index: 999;
    &__box {
        width: 480px;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
        border-radius: 8px;
        position: relative;
    }
    &__boxForm {
        width: 750px;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
        border-radius: 8px;
        position: relative;
    }
    &__content {
        padding-top: 8px;
        .c-select,
        .c-selectChange,
        .c-input {
            width: 100%;
        }
        .c-btn {
            &--flat {
                border: 1px solid #DADADA;
                justify-content: flex-start;
                svg {
                    margin-right: 8px;
                }
            }
        }
        .ant-form-item {
            width: 100%;
            margin: 16px 0 0;
        }
        .ant-upload.ant-upload-select {
            display: block;
        }
    }
    &__close {
        width: 44px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        img {
            width: 100%;
        }
    }
    &__icon {
        width: 96px;
        margin: 0 auto 8px;
        img {
            display: block;
            width: 100%;
        }
    }
    &__title {
        font-size: 24px;
        line-height: 36px;
        color: #4E5965;
        font-weight: 500;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    &__ttl {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        font-weight: 400;
        margin: 8px 0 0;
        text-align: center;
    }
    &__btn {
        margin: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
        }
    }
}