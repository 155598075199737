.c-btn {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #93A0AE;
    background: transparent;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    transition: .3s ease-in-out;
    position: relative;
    &--grey {
        color: #8E9BA9;
        background: #fff;
        &:hover {
            background: #8E9BA9;
            color: #fff;
            svg {
                path {
                    stroke: #fff;
                }
            }
        }
        &:disabled {
            cursor: not-allowed;
            background: #fff;
            color: #8E9BA9;
            opacity: .5;
        }
        svg {
            path {
                stroke: #8E9BA9;
            }
        }
    }
    &--greyBorder {
        color: #8E9BA9;
        background: #fff;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            border: 2px solid #8E9BA9;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover {
            background: #8E9BA9;
            color: #fff;
        }
        &:disabled {
            cursor: not-allowed;
            background: #fff;
            color: #8E9BA9;
            opacity: .5;
        }
    }
    &--primary {
        background: #FFD60A;
        color: #000;
        box-shadow: 0px 5px 10px rgba(255, 214, 10, 0.15);
        &:hover {
            background: #E1BC00;
        }
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background: #FFD60A;
        }
        svg {
            path {
                stroke: #292D32;
            }
        }
    }
    &--flat {
        background: #f9fafc;
        color: #4285F4;
        &:hover {
            background: #4285F4;
            color: #fff;
            svg {
                path {
                    stroke: #fff;
                }
            }
        }
        &:disabled {
            cursor: not-allowed;
            background: #fff;
            color: #4285F4;
            opacity: .5;
            svg {
                path {
                    stroke: #4285F4;
                }
            }
        }
        svg {
            path {
                transition: .3s ease-in-out;
                stroke: #4285F4;
            }
        }
    }
    &--blue {
        background: #4285F4;
        color: #fff;
        &:hover {
            background: #3C78DB;
        }
        &:disabled {
            cursor: not-allowed;
            background: #4285F4;
            opacity: .5;
        }
        svg {
            path {
                transition: .3s ease-in-out;
                stroke: #fff;
            }
        }
    }
    &--blueBorder {
        background: #fff;
        color: #4285F4;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            border: 2px solid #4285F4;
            border-radius: 10px;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover {
            background: #4285F4;
            color: #fff;
            svg {
                path {
                    stroke: #fff;
                }
            }
        }
        &:disabled {
            cursor: not-allowed;
            background: #fff;
            opacity: .5;
            color: #4285F4;
            svg {
                path {
                    stroke: #4285F4;
                }
            }
        }
        svg {
            path {
                transition: .3s ease-in-out;
                stroke: #4285F4;
            }
        }
    }

    img {
        display: block;
        margin-right: 5px;
    }
    svg {
        display: block;
    }
    &.is-left {
        svg {
            margin-right: 5px;
        }
    }
    &.is-right {
        svg {
            margin-left: 5px;
        }
    }
}

.c-label {
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    color: #4E5965;
    background: #FFFAE4;
    text-align: center;
    padding: 6px 10px;
    width: 120px;
    margin: 0 auto;
    &.is-Opening {
        background: #FAE3C2;
        color: #AD3113;
    }
    &.is-Completed {
        background: #DEF2D6;
        color: #5A7052;
    }
    &.is-opening {
        background: #FAE3C2;
        color: #AD3113;
    }
    &.is-completed {
        background: #DEF2D6;
        color: #5A7052;
    }
}