.c-toggle {
    display: flex;
    flex-wrap: wrap;
    &__box {
        display: block;
        cursor: pointer;
        input {
            display: none;
            &:checked {
                ~ span {
                    background: #4285F4 url(../images/icon-checked.svg) no-repeat center left 3px;
                    border: 2px solid #4285F4;
                    &:before {
                        left: 25px;
                        background: #fff;
                    }
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
        span {
            display: block;
            width: 48px;
            height: 24px;
            background: rgba(255, 255, 255, 0.88) url(../images/icon-checked.svg) no-repeat center left 3px;
            background-size: 16px;
            border: 2px solid #C1D4F5;
            border-radius: 100px;
            box-sizing: border-box;
            position: relative;
            transition: .2s ease-in-out;
            &:before {
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #4285F4;
                box-sizing: border-box;
                position: absolute;
                top: calc(50% - 8px);
                left: 3px;
                transition: .2s ease-in-out;
            }
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #4285F4;
                box-sizing: border-box;
                position: absolute;
                top: calc(50% - 6px);
                right: 4px;
                transition: .2s ease-in-out;
            }
        }
    }
    &__title {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 16px;
        color: #4E5965;
    }
    &__cont {
        width: 48px !important;
        height: 24px;
        margin: 0 !important;
        .ant-form-item-control-input {
            min-height: auto !important;
        }
    }
    &__custom {
        &.ant-switch {
            border: 2px solid #C1D4F5;
            width: 48px;
            height: 24px;
            background: rgba(255, 255, 255, 0.88) url(../images/icon-checked.svg) no-repeat center left 3px;
            background-size: 16px;
            .ant-switch-handle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #4285F4;
                position: absolute;
                top: calc(50% - 8px);
                left: 3px;
                z-index: 2;
                &:before {
                    display: none;
                    background: #4285F4;
                }
            }
            &:after {
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid #4285F4;
                box-sizing: border-box;
                position: absolute;
                top: calc(50% - 6px);
                right: 3px;
                transition: .2s ease-in-out;
                z-index: 1;
            }
            &:focus {
                box-shadow: none;
            }
        }
        &.ant-switch-checked {
            background: #4285F4 url(../images/icon-checked.svg) no-repeat center left 3px;
            border: 2px solid #4285F4;
            .ant-switch-handle {
                left: 25px;
                background: #fff;
            }
        }
    }
}
.bg_vestingteam{
    background-color: #F4F5F7;
    padding:10px;
    border-radius: 6px;
    margin-top: 10px;
}
.mt-10{
    margin-top: 15px; 
    
}
.c-radio {
    display: flex;
    flex-wrap: wrap;
    &__title {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
    }
    &__check {
        display: inline-block;
        padding: 0;
        margin: 0;
        
        cursor: pointer;
        input {
            display: none;
            &:checked {
               ~ span {
                    &:before {
                        background: #fff;
                        border: 7px solid #4285F4;
                    }
               }
            }
        }
        span {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            color: #000;
            margin: 0;
            padding: 0 0 0 32px;
            &:before {
                content: '';
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #C1D4F5;
                border: 0px solid #C1D4F5;
                position: absolute;
                top: calc(50% - 12px);
                left: 0;
                box-sizing: border-box;
                transition: .3s ease-in-out;
            }
        }
    }
    &__box {
        + .c-radio__box {
            margin-left: 140px;
        }
    }
    &__custom {
        width: 100%;
        margin: 0 !important;
        &.ant-form-item {
            .ant-radio-group {
                display: flex;
                align-items: center;
            }
            .ant-radio-wrapper {
                display: flex;
                align-items: center;
                color: #4E5965;
                font-size: 18px !important;
                line-height: 24px;
                margin: 0;
                padding: 0;
                + .ant-radio-wrapper {
                    margin-left: 140px;
                }
                .ant-radio {
                    .ant-radio-inner {
                        width: 24px;
                        height: 24px;
                        background: #C1D4F5;
                        border: 0;
                    }
                }
                .ant-radio-checked {
                    .ant-radio-inner {
                        background: #4285F4;
                        &::after {
                            width: 10px;
                            height: 10px;
                            transform: scale(1);
                            background: #fff;
                            margin-top: -5px;
                            margin-left: -5px;
                        }
                    }
                }
            }
            span {
                display: block;
                padding: 0;
                &.ant-radio {
                    line-height: 1;
                    top: 0;
                    + span {
                        padding-left: 16px;
                    }
                }
            }
        }
    }
}

.c-input {
    position: relative;
    width: 100%;
    input {
        appearance: none;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        padding: 15px;
        width: 100%;
        transition: .3s ease-in-out;
        &::placeholder {
            color: #93A0AE;
        }
        
        &:focus-visible {
            outline: none;
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:read-only {
            border: 1px solid #DADADA;
            box-shadow: none;
            outline: none;
        }
    }
    input[type='password'] {
        ~ .c-input__eye {
            &:after {
                opacity: 1;
            }
        }
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    &__box {
        position: relative;
        &.is-password {
            input {
                padding: 15px 50px 15px 15px;
            }
        }
    }
    &__eye {
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(50% - 12px);
        right: 15px;
        cursor: pointer;
        &:after {
            content: '';
            width: 1px;
            height: 100%;
            background: #8E9BA9;
            position: absolute;
            top: 0;
            left: 50%;
            transform: rotate(45deg);
            opacity: 0;
        }
        img {
            width: 100%;
            display: block;
        }
    }
    &__title {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
        span {
            color: #AD3113;
            margin-left: 5px;
        }
        h3 {
            font-size: 16px;
            line-height: 24px;
            padding: 0 28px 0 0;
            margin: 0;
            font-weight: normal;
            position: relative;
        }
        &.is-note {
            display: flex;
        }
    }
    &__note {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -9px;
        right: 0;
        cursor: pointer;
        svg {
            path {
                stroke: #71717A;
                transition: .3s ease-in-out;
            }
        }
        p {
            width: 300px;
            background: #22252F;
            border: 1px solid #71717A;
            border-radius: 5px;
            margin: 0;
            padding: 5px 10px;
            font-size: 16px;
            line-height: 24px;
            color: #C4C4C4;
            position: absolute;
            top: 100%;
            left: 0;
            transform: scale(0);
            transform-origin: top left;
            opacity: 0;
            transition: .3s ease-in-out;
            z-index: 9;
        }
        &:hover {
            svg {
                path {
                    stroke: #FFD60A;
                }
            }
            p {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
    &:hover {
        input {
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
            &:read-only {
                border: 1px solid #DADADA;
                box-shadow: none;
            }
        }
    }
}

.c-search {
    width: 100%;
    &__title {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
    }
    &__box {
        display: block;
        width: 100%;
        position: relative;
        &:hover {
            input {
                border: 1px solid #4285F4;
                box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
            }
        }
    }
    &__icon {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc(50% - 12px);
        right: 16px;
        img {
            display: block;
            width: 100%;
        }
    }
    input {
        appearance: none;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        padding: 15px 50px 15px 15px;
        width: 100%;
        transition: .3s ease-in-out;
        &::placeholder {
            color: #93A0AE;
        }
        
        &:focus-visible {
            outline: none;
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
    }
}

.c-textarea {
    position: relative;
    width: 100%;
    &__title {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
        padding: 0;
    }
    &__custom {
        margin: 0 !important;
    }
    textarea {
        display: block;
        appearance: none;
        resize: none;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        padding: 16px;
        width: 100%;
        height: 225px;
        transition: .3s ease-in-out;
        &::placeholder {
            color: #93A0AE;
        }
        &:focus-visible {
            outline: none;
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
        &:hover {
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
    }
}

.c-date {
    &__title {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
    }
    .ant-picker {
        width: 100%;
        background: #F4F5F7;
        border: 1px solid #DADADA;
        border-radius: 5px;
        color: #4E5965;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.025em;
        padding: 15px 16px;
        &-input {
            > input {
                color: #4E5965;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.025em;
            }
        }
        &-range {
            &-separator {
                padding: 0 16px;
            }
        }
        &-focused {
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
    }
}

.ant-form input[type='file']
{
    display: none !important;
}
.c-upload {
    width: 100%;
    
    &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        padding: 0;
        margin: 0 0 8px; 
    }
    &__box {
        display: block;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer; 
        .btn-click{
            width: 100%;
            height: 100%; 
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                path {
                    stroke: #8E9BA9;
                }
            }
            p {
             color: #8E9BA9;
             margin-bottom: 0;
             margin-top:0;
             margin-left:5px;
            }
        }
        &.is-add,
        &.is-addSmall {
            background: #F4F5F7;
            border: 1px solid #DADADA;
            border-radius: 10px;
            min-height: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            .c-btn {
                &--grey {
                    background: transparent;
                    &:hover {
                        color: #8E9BA9;
                        svg {
                            path {
                                stroke: #8E9BA9;
                            }
                        }
                    }
                }
            }
        }
        &.is-addSmall {
            min-height: auto;
            height: 56px;
        }
        .ant-upload {
            &.ant-upload-select {
                display: block;
            }
            &-list {
                display: none;
            }
        }
        img {
            display: block;
            height: 240px;
            width: 100%;
            object-fit: contain;
        }
    }
    &__edit {
        display: flex;
        position: relative;
        justify-content: center;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .6;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }
        &:after {
            content: '';
            width: 32px;
            height: 32px;
            background: url(../images/icon-editIMG.svg) no-repeat center;
            background-size: 32px;
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 1;
        }
        &.is-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                height: 56px;
                object-fit: contain;
            }
        }
    }
    input {
        display: none;
    }
}

.c-select {
    width: 100%;
    &__title {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
    }
    &__box {
        width: 100%;
        background: #FFFFFF;
        position: relative;
        &:after {
            content: '';
            width: 24px;
            height: 24px;
            background: url(../images/icon-arrow-down.svg) no-repeat center;
            background-size: 24px;
            position: absolute;
            top: calc(50% - 12px);
            right: 16px;
            z-index: 0;
        }
    }
    select {
        width: 100%;
        appearance: none;
        background: transparent;
        box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
        border-radius: 4px;
        border: 1px solid #DADADA;
        font-size: 16px;
        line-height: 24px;
        padding: 15px 40px 15px 16px;
        transition: .3s ease-in-out;
        position: relative;
        z-index: 1;
        cursor: pointer;
        &:hover {
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
        &:focus-visible {
            outline: none;
            border: 1px solid #4285F4;
            box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
        }
    }
}

//Select Custom Antd
.ant-select.c-selectChange {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    // box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #4E5965;
    transition: .3s ease-in-out;
    &:not(.ant-select-customize-input) {
        .ant-select-selector {
            height: auto;
            padding: 12px 30px 12px 16px;
            border: 0;
            border-radius: 10px;
            .ant-select-selection-item {
                display: flex;
                align-items: center;
                img {
                    display: block;
                    width: 24px;
                    margin-right: 8px;
                }
            }
        }
    }
    .ant-select-selection-placeholder {
        color: #93A0AE;
    }
    .ant-select-arrow {
        right: 16px;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        border: 1px solid #4285F4;
        box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
    }
    &:focus,
    &:focus-within,
    &:focus-visible {
        outline: none;
        border: 1px solid #4285F4;
        box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
    }
}

.ant-select-dropdown {
    .ant-select-item {
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        min-height: auto;
        padding: 8px 16px;
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            img {
                display: block;
                width: 24px;
                margin-right: 8px;
            }
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: #fff;
        background: #4285F4;
        font-weight: normal;
    }
}

.c-dateCustom {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #4E5965;
    padding: 15px;
    width: 100%;
    transition: .3s ease-in-out;
    &::placeholder {
        color: #93A0AE;
    }
    
    &:focus-visible {
        outline: none;
        border: 1px solid #4285F4;
        box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
    }
    &:hover {
        border: 1px solid #4285F4;
        box-shadow: 0px 5px 10px rgba(66, 133, 244, 0.15);
    }
    &.is-bg {
        background: #F4F5F7;
    }
}