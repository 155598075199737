.c-nav {
    list-style-type: none;
    padding: 24px 8px 24px 16px;
    margin: 0;
    > li {
        display: block;
        position: relative;
        + li {
            margin-top: 24px;
        }
        > a,
        > span {
            display: block;
            background: transparent;
            border-radius: 5px;
            padding: 6px 40px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            text-decoration: none;
            position: relative;
            cursor: pointer;
            transition: .3s ease-in-out;
            &:hover {
                background: #F4F5F7;
            }
            &.is-active {
                background: #FFD60A;
                color: #4E5965;
                .c-nav__icon {
                    opacity: 1;
                }
            }
        }
        &.is-0 {
            .is-campaign-0 {
                background: #FFD60A;
                color: #4E5965;
                .c-nav__icon {
                    opacity: 1;
                }
            }
        }
        &.is-1 {
            .is-aridrop-1 {
                background: #FFD60A;
                color: #4E5965;
                .c-nav__icon {
                    opacity: 1;
                }
            }
        }
        &.is-2 {
            .is-gamehub-2 {
                background: #FFD60A;
                color: #4E5965;
                .c-nav__icon {
                    opacity: 1;
                }
            }
        }
        &.is-4 {
            .setting {
                background: #FFD60A;
                color: #4E5965;
                .c-nav__icon {
                    opacity: 1;
                }
            }
        }
    }
    &__icon {
        width: 24px;
        position: absolute;
        left: 8px;
        top: calc(50% - 12px);
        opacity: .5;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
    }
    &__arrow {
        width: 24px;
        position: absolute;
        right: 8px;
        top: 6px;
        z-index: 2;
        cursor: pointer;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__sub {
        max-height: 0;
        overflow: hidden;
        list-style-type: none;
        margin: 0;
        padding: 0;
        transition: .3s ease-in-out;
        &.is-open {
            max-height: 240px;
        }
        > li {
            position: relative;
            display: block;
            > a {
                display: block;
                background: transparent;
                border-radius: 5px;
                color: #93A0AE;
                font-size: 16px;
                line-height: 1;
                padding: 10px 5px 10px 40px;
                transition: .3s ease-in-out;
                position: relative;
                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #8E9BA9;
                    position: absolute;
                    top: calc(50% - 3px);
                    left: 24px;
                    transition: .3s ease-in-out;
                }
                &:hover {
                    background: #F4F5F7;
                }
                &.is-active {
                    color: #4E5965;
                    &:before {
                        background: #FFD60A;
                        box-shadow: 0 0 3px 3px #FFFAE4;
                    }
                }
            }
        }
    }
}