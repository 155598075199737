.c-header {
    border-bottom: 1px solid #DADADA;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px 16px 40px;
    h3 {
        font-size: 30px;
        line-height: 1;
        color: #4E5965;
        padding: 0;
        margin: 0;
        font-weight: bold;
    }
    &__box {
        display: flex;
        align-items: center;
    }
    &__notifi {
        width: 80px;
        height: 65px;
        border: 1px solid #DADADA;
        border-radius: 5px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            background: #AD3113;
            border-radius: 50%;
            font-size: 14px;
            line-height: 1;
            color: #fff;
            position: absolute;
            top: 12px;
            right: 20px;
        }
    }
    &__name {
        display: flex;
        align-items: center;
        padding: 12px 12px;
        width: 180px;
        height: 65px;
        border: 1px solid #DADADA;
        border-radius: 5px;
        background: #fff;
        font-size: 16px;
        line-height: 1;
        color: #4E5965;
        margin-left: 24px;
        img {
            display: block;
            width: 40px;
            margin-right: 8px;
        }
    }
    
}
.ant-dropdown {
    .ant-dropdown-menu {
        margin-top: 22px !important;
        margin-right: -16px;
        right: 0 !important;
        width: 180px !important;
        .ant-dropdown-menu-item {
            padding: 12px 20px !important;
            font-size: 15px !important;
        }
    }
}