.c-logo {
    padding: 20px;
    height: 95px;
    position: relative;
    span {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: -12px;
        top: calc(50% - 12px);
        transition: .3s ease-in-out;
        &.is-toggle {
            transform: rotate(180deg);
        }
    }
}

.c-title {
    h3 {
        font-size: 22px;
        line-height: 22px;
        font-weight: bold;
        color: #4E5965;
        padding: 0;
        margin: 0;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 0;
        letter-spacing: 0.025em;
        color: #4E5965;
    }
}

.c-ttl {
    width: 100%;
    font-size: 20px;
    line-height: 1;
    color: #4E5965;
    font-weight: 400;
    margin: 0;
    padding: 0;
    &.is-font16 {
        font-size: 16px;
        line-height: 24px;
    }
}

.c-tabs {
    .ant-tabs-nav {
        margin: 0 0 25px !important;
        &:before {
            display: none;
        }
    }
    .ant-tabs-ink-bar {
        display: none;
    }
    .ant-tabs-tab {
        padding: 0 10px 2px;
        font-size: 18px;
        line-height: 24px;
        color: #4E5965;
        position: relative;
        &:after {
            content: '';
            width: 0;
            height: 2px;
            background: #4285F4;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: .3s ease-in-out;
        }
        &-active,
        &:hover {
            color: #4285F4;
            &:after {
                width: 100%;
            }
        }
    }
}

.c-table {
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        background: #fff;
        border-top: 1px solid #DADADA;
        border-right: 1px solid #DADADA;
        border-left: 1px solid #DADADA;
        .c-selectChange {
            margin-right: 24px;
        }
        .c-search {
            width: 260px;
            margin-right: 24px;
        }
    }
    &__sort {
        width: 14px;
        height: 22px;
        position: absolute;
        top: calc(50% - 11px);
        right: 10px;
        span {
            display: block;
            width: 100%;
            height: 11px;
            position: absolute;
            cursor: pointer;
            opacity: .5;
            &.is-active {
                opacity: 1;
            }
            &.is-sortUp {
                top: 0;
                left: 0;
                &:before {
                    background: url(../images/icon-sortUp.svg) no-repeat center;
                    background-size: 100%;
                    top: 0;
                    left: 0;
                }
            }
            &.is-sortDown {
                bottom: 0;
                left: 0;
                &:before {
                    background: url(../images/icon-sortDown.svg) no-repeat center;
                    background-size: 100%;
                    bottom: 0;
                    left: 0;
                }
            }
            &:before {
                content: '';
                width: 100%;
                height: 11px;
                position: absolute;
            }
        }
    }
    .ant-table-thead,
    .ant-table-tbody {
        > tr {
            > th,
            > td {
                border-bottom: 0;
                background: transparent;
            }
        }
    }
    .ant-table-cell-ellipsis {
        white-space: break-spaces;
        .ant-table-column-title {
            word-break: break-word;
            white-space: break-spaces;
        }
    }
    .ant-table-pagination {
        &.ant-pagination {
            margin: 32px 0 0;
        }
        .ant-pagination {
            &-prev,
            &-next,
            &-item {
                min-width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F4F5F7;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                line-height: 24px;
                color: #93A0AE;
                transition: .3s ease-in-out;
                border: 0;
                a {
                    color: #93A0AE;
                }
                button {
                    border: 0;
                    font-size: 16px;
                    line-height: 24px;
                    border-radius: 4px;
                    &:disabled {
                        background: #F4F5F7;
                        opacity: 0.5;
                    }
                }
                &-active {
                    background: #FFD60A;
                    a {
                        color: #4E5965;
                    }
                }                
            }
            &-item {
                margin-right: 16px;
            }
        }
        .ant-pagination-prev {
            margin-right: 16px;
        }
    }
    .ant-table-title {
        padding: 0;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #DADADA;
        &.is-col7 {
            th,
            td {
                width: calc(100% / 7);
            }
        }
        &.is-report {
            background: #fff;
            th,
            td {
                border: 1px solid #DADADA;
                width: calc(100% / 7);
            }
            th {
                padding: 32px 5px;
            }
            td {
                padding: 24px 5px;
                &.is-bg {
                    background: #F4F5F7;
                }
                &.is-color {
                    color: #B86D00;
                    font-weight: bold;
                    span {
                        color: #ECBC76;
                    }
                }
                span {
                    color: #93A0AE;
                    display: block;
                }
            }
        }
        thead {
            th {
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                color: #697A8D;
                padding: 8px 10px;
                font-weight: normal;
                text-align: center;
                position: relative;
                &.is-sort {
                    padding-right: 24px;
                }
                &.is-border {
                    border-right: 1px solid #DADADA;
                }
            }
        }
        tbody {
            td {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #4E5965;
                padding: 24px 5px;
                border-top: 1px solid #DADADA;
                &.is-date {
                    span {
                        display: block;
                    }
                }
                &.is-border {
                    border-right: 1px solid #DADADA;
                }
                .c-btn {
                    margin: 0 auto;
                    background: transparent;
                    &:hover {
                        background: #4285F4;
                        color: #fff;
                        svg {
                            path {
                                stroke: #fff;
                            }
                        }
                    }
                }
                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    text-decoration-line: underline;
                    color: #4285F4;
                }
            }
        }
    }
    &--remaining {
        table {
            tbody {
                tr {
                    &:nth-child(2n) {
                        td {
                            background: #F4F5F7;
                        }
                    }
                    td {
                        padding: 16px 5px;
                    }
                }
            }
        }
    }
    &--whitelist {
        table {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #DADADA;
        }
        .ant-table-pagination {
            padding: 0 24px 24px 0;
        }
    }
    &--allocation {
        .c-table__head {
            border: 0;
        }
        table {
            border-top: 1px solid #DADADA;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #DADADA;
            tbody {
                td {
                    padding: 16px 5px;
                }
            }
        }
        .ant-table-pagination {
            padding: 0 24px 24px 0;
        }
    }
    &--systemAdmin {
        .ant-table-cell-ellipsis {
            white-space: nowrap;
        }
        table {
            tbody {
                td {
                    a {
                        color: #4285F4;
                        text-decoration: none;
                    }
                    &.is-padd16 {
                        padding: 8px 5px;
                    }
                }
            }
        }
    }

    .anticon {
        color: #8E9BA9;
    }
}

.c-actionTable {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &__box {
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: relative;
        &:hover {
            .c-actionTable__content {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
    &__content {
        width: 180px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 14px 24px rgba(51, 51, 51, 0.12);
        border-radius: 4px;
        padding: 8px 0;
        position: absolute;
        top: 100%;
        right: 0;
        transition: .3s ease-in-out;
        list-style-type: none;
        overflow: hidden;
        transform: scale(0);
        transform-origin: top right;
        opacity: 0;
        z-index: 9;
        li {
            a,
            span {
                display: block;
                padding: 8px 16px;
                color: #4E5965 !important;
                font-size: 16px;
                line-height: 24px;
                text-decoration: none !important;
                text-align: left !important;
                cursor: pointer;
                transition: .3s ease-in-out;
                &:hover {
                    background: #F4F5F7;;
                }
            }
        }
    }
}

.c-nameTable {
    text-align: center;
    &__logo {
        margin-bottom: 8px;
        img {
            width: 75px;
        }
    }
    &__title {
        font-size: 16px;
        line-height: 16px;
        margin: 0;
    }
    &.is-tokenInform {
        display: flex;
        align-items: center;
        justify-content: center;
        .c-nameTable__logo {
            margin: 0 8px 0 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                display: block;
            }
        }
    }
}

.c-pagination {
    margin: 32px 0 0;
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
        display: flex;
        align-items: center;
        &-prev,
        &-next,
        &-item {
            min-width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F4F5F7;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            transition: .3s ease-in-out;
            border: 0;
            a {
                color: #93A0AE;
            }
            button {
                border: 0;
                font-size: 16px;
                line-height: 24px;
                border-radius: 4px;
                &:disabled {
                    background: #F4F5F7;
                    opacity: 0.5;
                }
            }
            &-active {
                background: #FFD60A;
                a {
                    color: #4E5965;
                }
            }
            background: #F4F5F7;
            &:hover {
                background: #FFD60A;
                a {
                    color: #4E5965;
                }
            }    
        }
        &-item {
            margin-right: 16px;
        }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        button {
            background: #F4F5F7;
            color: #93A0AE;
            &:hover {
                background: #FFD60A;
                color: #4E5965;
            }
        }
    }
    .ant-pagination-disabled {
        button {
            &:hover {
                background: #F4F5F7;
                color: #8E9BA9;
            }
        }
    }
    .ant-pagination-prev {
        margin-right: 16px;
    }
    .ant-pagination-item-link {
        &:hover {
            .ant-pagination-item-link-icon {
                color: #4E5965;
            }
        }
    }
    .ant-pagination-options {
        .ant-select {
            font-size: 16px;
            color: #93A0AE;
            .ant-select-selector {
                background: #F4F5F7;
                border-radius: 4px;
                border: 0;
                padding: 10px 16px;
                height: 44px;
                &:after {
                    color: #93A0AE;
                }
            }
            .ant-select-selection-item {
                line-height: 24px;
            }
            &:hover {
                color: #4E5965;
                .ant-select-selector {
                    background: #FFD60A;
                }
            } 
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            box-shadow: none;
            border: 0;
            outline: none;
            background: #FFD60A;
        }
        .ant-select-single.ant-select-open .ant-select-selection-item {
            color: #4E5965;
        }
    }
}

.c-pager {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
            + li {
                margin-left: 16px;
            }
            button,
            a {
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                appearance: none;
                border: 0;
                background: #F4F5F7;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                line-height: 24px;
                color: #93A0AE;
                transition: .3s ease-in-out;
                &:hover {
                    background: #FFD60A;
                }
            }
            &.is-active {
                a {
                    background: #FFD60A;
                }
            }
        }
    }
}

.c-info {
    &__img {
        border-radius: 5px 5px 0px 0px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
        }
    }
    &__logo {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 18px;
            line-height: 18px;
            margin: 0;
            color: #4E5965;
        }
        span {
            display: block;
            width: 80px;
            img {
                width: 100%;
            }
        }
    }
    &__row {
        margin-top: 32px;
    }
    &__dflex {
        display: flex;
        justify-content: space-between;
        .c-info__row {
            margin-top: 16px;
            &:last-child {
                .c-info__txt {
                    text-align: right;
                }
            }
        }
    }
    &__ttl {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #93A0AE;
        padding: 0;
        margin: 0 0 8px;
    }
    &__txt {
        padding: 0;
        margin: 0;
        color: #4E5965;
        font-size: 16px;
        line-height: 24px;
        img {
            width: 24px;
            margin-right: 8px;
        }
    }
    &__media {
        list-style-type: none;
        padding: 0;
        margin: 8px 0 16px;
        li {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            + li {
                margin-top: 16px;
            }
            span {
                width: 105px;
                display: block;
                img {
                    width: 20px;
                    margin-right: 8px;
                }
            }
            p {
                width: calc(100% - 105px);
                margin: 0;
                padding-left: 16px;
                text-overflow: ellipsis;
                word-break: keep-all;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
    &__box {
        background: #fff;
        border: 1px solid #DADADA;
        border-radius: 5px;
        padding: 16px;
        .c-info__row {
            margin-top: 24px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.c-roundInfo {
    margin-bottom: 16px;
    &__head {
        padding: 12px 70px 12px 24px;
        background: #F4F5F7;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .c-title {
            margin-bottom: 0;
            h3 {
                font-size: 18px;
                line-height: 27px;
                color: #000;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                margin: 8px 0 0;
            }
        }
        .c-btn {
            &--flat {
                background: #F4F5F7;
                &:hover {
                    background: #4285F4;
                    color: #fff;
                }
            }
        }
    }
    &__btnRound {
        width: 100%;
        display: flex;
        margin-top: 16px;
        .c-btn {
            + .c-btn {
                margin-left: 32px;
            }
        }
    }
    &__toggle {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
        right: 24px;
        cursor: pointer;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__body {
        max-height: 0;
        transition: .3s ease-in-out;
        overflow: hidden;
        &.is-open {
            max-height: 2000px;
        }
    }
    &__content {
        padding: 16px 24px;
    }
    &__dflex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .c-roundInfo__row {
            width: calc(100%/3);
            padding-right: 5px;
            margin: 0 0 24px;
            &:nth-child(3n) {
                text-align: right;
            }
            &.is-wAnno {
                width: calc((100%/3)*2);
            }
        }
    }
    &__row {
        margin: 24px 0 0;
        width: 100%;
        &.is-mt0 {
            margin: 0;
        }
    }
    &__box {
        background: #F9FAFC;
        border-radius: 5px;
        margin-top: 8px;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &.is-vesting {
            .c-roundInfo__row {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    width: 50%;
                    margin-top: 0;
                }
                &:nth-of-type(2) {
                    text-align: right;
                }
            }
        }
        .c-roundInfo__row {
            &:nth-of-type(1) {
                margin-top: 0;
            }
        }
    }
    &__ttl {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #93A0AE;
        margin: 0 0 8px;
        padding: 0;
    }
    &__txt {
        width: 100%;
        padding: 0;
        margin: 0;
        color: #4E5965;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        &.is-font16 {
            font-size: 16px;
        }
    }
    &__vesting {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            color: #4E5965;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            + li {
                margin-top: 16px;
            }
        }
    }
}

.c-round {
    margin-bottom: 16px;
    &__head {
        padding: 16px 70px 16px 32px;
        background: #F4F5F7;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        .c-btn {
            &--flat {
                background: #F4F5F7;
                &:hover {
                    background: #4285F4;
                    color: #fff;
                }
            }
        }
    }
    &__toggle {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 30px;
        right: 32px;
        cursor: pointer;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__body {
        max-height: 0;
        transition: .8s ease-in-out;
        overflow: hidden;
        &.is-open {
            max-height: 5000px;
        }
    }
    &__content {
        padding: 32px;
    }
    &__row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 24px;
        &.is-col3 {
            width: calc(100% + 38px);
            margin-right: -38px;
            margin-top: 0;
            .c-select {
                width: calc(100%/3 - 8px);
                margin-bottom: 0;
                .ant-form-item {
                    width: 100%;
                    margin-bottom: 0;
                }
            }
            .ant-form-item {
                width: calc(100%/3 - 8px);
                margin-bottom: 0;
                .c-input {
                    width: 100%;
                    margin-bottom: 0;
                }
            }
        }
        .ant-form-item {
            width: calc(50% - 23px);
            margin-bottom: 24px;
            .c-input {
                width: 100%;
            }
        }
        
    }
    &__vesting,
    &__timeTable {
        width: calc(50% - 23px);
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        margin-top: 8px;
        padding: 24px 16px;
        .c-repeater,
        .c-textarea,
        .c-radio,
        .ant-form-item {
            width: 100%;
            margin: 24px 0 0;
        }
        .c-date {
            margin-top: 24px;
        }
    }
    &__refund-especial {
        width: 100%;

        &__col {
            padding-top: 30px;
            width: calc(50% - 10px);
        }
    }
}

.c-repeater {
    &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0;
        padding: 0;
    }
    &__row {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .c-date {
            width: calc(100% - 194px);
            margin-top: 0;
            .ant-picker {
                background: #fff;
            }
            .ant-form-item {
                width: 100%;
                margin: 0;
            }
        }
        .ant-form-item {
            width: 144px;
            margin: 0 0 0 16px;
        }
    }
    &__remove {
        width: 24px;
        border: 0;
        padding: 0;
        margin-right: 10px;
        cursor: pointer;
        background: transparent;
        img {
            display: block;
            width: 100%;
        }
    }
    .c-btn {
        margin-top: 16px;
        width: 240px;
    }
}

.c-media {
    list-style-type: none;
    margin: 8px 0 0;
    padding: 0;
    width: 100%;
    li {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        + li {
            margin-top: 8px;
        }
        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin: 0;            
            &:first-child {
                width: 172px;
                margin-right: 8px;
            }
            &:last-child {
                width: calc(100% - 180px);
            }
            span {
                color: #4285f4;
            }
        }
        h3 {
            display: flex;
            align-items: center;
            width: 172px;
            margin: 0 8px 0 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: normal;
            color: #4E5965;
            background: #FFFFFF;
            border: 1px solid #DADADA;
            border-radius: 10px;
            padding: 15px 16px;
            img {
                display: block;
                width: 20px;
                margin-right: 8px;
            }
        }
        .c-input {
            width: calc(100% - 180px);
        }
        .inputCustom{
            width: calc(100% - 180px);
            .ant-form-item{
                margin-bottom: 0;
            } 
            .ant-col-16{
                max-width: 100%;
               
            }
        }
    }
}

.c-noData {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: 100%;
    border-top: 1px solid #DADADA;
}

.c-listAddress {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    margin-top: 32px;
    &__head {
        padding: 18px 72px 18px 21px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    &__toggle {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(50% - 15px);
        right: 21px;
        cursor: pointer;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        color: #4E5965;
        padding: 0;
        margin: 0;
    }
    &__btn {
        display: flex;
        align-items: center;
        .c-search {
            width: 260px;
            margin-right: 24px;
        }
        .c-btn {
            + .c-btn {
                margin-left: 24px;
            }
        }
    }
    &__body {
        max-height: 0;
        transition: .5s ease-in-out;
        overflow: hidden;
        &.is-open {
            max-height: 1200px;
        }
    }
    &__info {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        background: #FFFAE4;
        li {
            display: block;
            width: calc(100%/9);
            padding: 12px 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #B86D00;
            text-align: center;
        }
    }
}
.c-priorityList {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    margin-top: 32px;
    &__head {
        padding: 18px 72px 18px 21px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    &__toggle {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(50% - 15px);
        right: 21px;
        cursor: pointer;
        transition: .3s ease-in-out;
        img {
            display: block;
            width: 24px;
        }
        &.is-open {
            transform: rotate(180deg);
        }
    }
    &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        color: #4E5965;
        padding: 0;
        margin: 0;
    }
    &__btn {
        display: flex;
        align-items: center;
        .c-search {
            width: 260px;
            margin-right: 24px;
        }
        .c-btn {
            + .c-btn {
                margin-left: 24px;
            }
        }
    }
    &__body {
        max-height: 0;
        transition: .5s ease-in-out;
        overflow: hidden;
        &.is-open {
            max-height: 1200px;
        }
    }
    &__info {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        background: #FFFAE4;
        li {
            display: block;
            width: calc(100%/3);
            padding: 12px 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #B86D00;
            text-align: center;
        }
    }
}
.c-claimTokenSchedule {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    padding: 24px 16px;
    + .c-claimTokenSchedule {
        margin-top: 16px;
    }
    &__title {
        font-size: 16px;
        line-height: 1;
        color: #4E5965;
        font-weight: normal;
        padding: 0;
        margin: 0 0 24px;
    }
}

.c-schedule {
    padding-left: 50px;
    position: relative;
    + .c-schedule {
        margin-top: 16px;
    }
    &__btn {
        background: #FFFFFF;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        width: 50px;
        height: 70px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 24px;
            display: block;
            transition: .3s ease-in-out;
        }
    }
    &__body {
        max-height: 70px;
        overflow: hidden;
        transition: .3s ease-in-out;
        &.is-open {
            max-height: 100%;
        }
    }
    &__content {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: block;
        width: 100%;        
        li {
            display: flex;
            align-items: center;
            background: #F4F5F7;
            + li {
                border-top: 1px solid #DADADA;
            }
        }
    }
    &__txt,
    &__link {
        display: block;
        width: calc(100%/6);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #4E5965;
        padding: 8px 8px;
        margin: 0;
        .c-btn {
            margin: 0 auto;
            &--flat {
                background: transparent;
                &:hover {
                    background: #4285F4;
                }
            }
        }
        span {
            display: block;
            &.is-color {
                color: #B86D00;
                font-weight: bold;
            }
            &.is-ttl {
                display: block;
                color: #93A0AE;
                margin-bottom: 8px;
            }
            &.is-txt {
                display: block;
                color: #93A0AE;
                margin-top: 24px;
            }
        }
    }
    &__link {
        color: #4285F4;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
    }
    &__box {
        padding: 16px 0 16px 16px;
        background: #F4F5F7;
        display: flex;
        border-top: 1px solid #DADADA;
    }
    &__info {
        width: calc(100%/6);
        &:first-child {
            width: calc(100%/6 * 3);
            .c-schedule__txt {
                text-align: left;
            }
        }
        .c-schedule__txt {
            width: 100%;
        }
    }
}

.c-img {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #4E5965;
    padding: 5px;
    img {
        display: block;
        width: auto;
        height: 44px;
        margin-right: 8px;
        border-radius: 10px;
    }
}

.c-uploadIMG {
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    .c-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        input[type='file'] {
            cursor: pointer;
        }
    }
    .c-btn {
        position: relative;
        z-index: 1;
    }
    &:hover {
        .c-btn {
            background: #4285F4;
            color: #fff;
            svg {
                path {
                    stroke: #fff;
                }
            }
        }
    }
}

.c-uploadFile {
    margin-top: 16px;
    border: 1px dashed #4285F4;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    .c-input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        &__box {
            cursor: pointer;
            height: 100%;
            input {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }
    &__box {
        width: 100%;
        padding: 24px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        cursor: pointer;
        p {
            display: block;
            width: 100%;
            color: #93A0AE;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin: 0;
        }
    }
}

.c-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1000;
    overflow: hidden;
    img {
        width: 500px;
    }
}
.rnc__notification {
    width: 650px !important;
}
.ant-select-selection-search {

    input {
        margin: 10px 5px 10px 5px;
    }
}

.airdrop-upload{
    width: 100%;
    img{
        width: 100%;
        height: auto;
    }
}
.add_img{
    margin-top: 20px;
    margin-bottom: 0px;
}

.c-inoBox {
    &__head {
        display: flex;
        align-items: center;
        .c-ttl {
            width: 50%;
        }
    }
    &__btn {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .c-btn {
            + .c-btn {
                margin-left: 20px;
            }
        }
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px 5px 0px 0px;
        padding: 24px;
        margin-top: 16px;
        .c-noData {
            border-top: 0;
        }
    }
    &__item {
        background: #F9FAFC;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 5px;
        + .c-inoBox__item {
            border-top: 1px solid #DADADA;
            border-radius: 0;
        }
        &:first-child {
            border-radius: 5px 5px 0 0;
        }
        &:last-child {
            border-radius: 0 0 5px 5px;
        }
    }
    &__img {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        img {
            width: 75px;
            display: block;
        }
    }
    &__info {
        width: calc(25% - 5px);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin-bottom: 24px;
        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            margin-bottom: 8px;
        }
    }
    &__des {
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin-bottom: 0;
        }
        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            margin-bottom: 8px;
        }
    }
}

.c-inoTimeLine {
    margin-top: 32px;
    &__head {
        display: flex;
        align-items: center;
        .c-ttl {
            width: 40%;
        }
    }
    &__btn {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .c-btn {
            + .c-btn {
                margin-left: 20px;
            }
        }
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        margin-top: 8px;
        padding: 24px;
        .c-noData {
            border: 0;
        }
        .c-repeater {
            &__row {
                width: 100%;
                align-items: flex-start;
                .ant-form-item {
                    width: calc(50% - 16px);
                }
            }
            &__remove {
                margin-right: 0;
                margin-top: 16px;
            }
            textarea {
                box-sizing: border-box;
                background: #FFFFFF;
                border: 1px solid #DADADA;
                border-radius: 10px;
                font-size: 16px;
                line-height: 24px;
                color: #4E5965;
                width: 100%;
                transition: 0.3s ease-in-out;
                &:hover,
                &:focus-visible {
                    border: 1px solid #4285F4;
                    box-shadow: 0px 5px 10px rgb(66 133 244 / 15%);
                }
            }
        }
    }
}

.c-inoRule {
    margin-top: 32px;
    &__head {
        display: flex;
        align-items: center;
        .c-ttl {
            width: 50%;
        }
    }
    &__btn {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .c-btn {
            + .c-btn {
                margin-left: 20px;
            }
        }
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        margin-top: 8px;
        padding: 24px;
        .c-repeater {
            &__row {
                width: 100%;
                align-items: flex-start;
                .ant-form-item {
                    width: calc(100% - 16px);
                }
            }
            &__remove {
                margin-right: 0;
                margin-top: 16px;
            }
        }
    }
}

.c-inoInfoCampaign {
    margin-top: 24px;
    &.is-edit {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px 5px 0px 0px;
        padding: 24px;
        margin-top: 16px;
    }
    &__img {
        img {
            display: block;
            width: 100%;
        }
    }
    &__name {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 24px;
        p {
            margin: 0;
            width: calc(100% - 90px);
            font-size: 18px;
            color: #4E5965;
        }
        img {
            display: block;
            width: 80px;
        }
    }
    &__info {
        margin-top: 24px;
        &.is-mt0 {
            margin-top: 0;
        }
        span {
            display: block;
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            &.is-font18 {
                font-size: 18px;
                color: #4E5965;
            }
        }
        &.is-textRight {
            span {
                text-align: right;
            }
        }
    }
    &__des {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin-bottom: 0;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin-bottom: 0;
        img {
            width: 80px;
            display: block;
        }
        &.is-network {
            display: flex;
            img {
                display: block;
                width: 24px;
                margin-right: 8px;
            }
        }
        &.is-textRight {
            text-align: right;
        }      
    }
    &__row {
        display: flex;
        justify-content: space-between;        
    }
    &__media {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            align-items: center;
            + li {
                margin-top: 16px;
            }
            span {
                width: 150px;
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 24px;
                color: #4E5965;
                img {
                    width: 20px;
                    display: block;
                    margin-right: 10px;
                }
            }
            p {
                width: calc(100% - 150px);
                font-size: 16px;
                line-height: 24px;
                color: #4E5965;
                margin: 0;
            }
        }
    }
    &__staking {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        padding: 16px;
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin-bottom: 0;
            + p {
                margin-top: 24px;
            }
            span {
                display: block;
                margin-bottom: 8px;
                font-size: 16px;
                line-height: 24px;
                color: #93A0AE;
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                font-size: 16px;
                line-height: 24px;
                color: #4E5965;
                + li {
                    margin-top: 16px;
                }
                span {
                    display: block;
                    padding-left: 20px;
                    margin: 8px 0 0;
                    color: #4E5965;
                }
            }
        }
    }
}

.c-inoBoxInfo {
    margin-top: 32px;
    &__head {
        display: flex;
        align-items: center;
        .c-ttl {
            width: 40%;
        }
    }
    &__btn {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .c-btn {
            + .c-btn {
                margin-left: 20px;
            }
        }
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        margin-top: 8px;
        padding: 24px;
        .c-noData {
            border: 0;
        }
        &.is-rule {
            .c-inoBoxInfo__row {
                p {
                    &:first-child {
                        width: calc(100% - 100px);
                    }
                }
            }
        }
    }
    &__info {
        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            margin-bottom: 8px;
        }
        + .c-inoBoxInfo__info {
            margin-top: 24px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: flex;
                align-items: flex-start;
                + li {
                    margin-top: 16px;
                }
                span {
                    width: 150px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 24px;
                    color: #4E5965;
                    img {
                        width: 20px;
                        display: block;
                        margin-right: 10px;
                    }
                }
                p {
                    width: calc(100% - 150px);
                    font-size: 16px;
                    line-height: 24px;
                    color: #4E5965;
                    margin: 0;
                    word-break: break-all;
                }
            }
        }
    }
    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin-bottom: 0;
    }
    &__ttl {
        display: flex;
        p {
            width: calc(100%/2 - 50px);
            margin-bottom: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #93A0AE;
            border-bottom: 1px solid #DADADA;
            padding-bottom: 16px;
            + p {
                padding-left: 16px;
            }
        }
    }
    &__row {
        display: flex;
        padding: 16px 0;
        + .c-inoBoxInfo__row {
            border-top: 1px solid #DADADA;
        }
        p {
            width: calc(100%/2 - 50px);
            margin: 0;
            background: #FFFFFF;
            border: 1px solid #DADADA;
            border-radius: 9px;
            padding: 16px;
            margin-left: 16px;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            &:first-child {
                margin-left: 0;
            }
            &.is-btn {
                width: 100px;
                display: flex;
                justify-content: flex-end;
                padding: 16px 0;
                border: 0;
                margin-left: 0;
                span {
                    display: block;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    + span {
                        margin-left: 16px;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.flex-display{
    display: flex;
    justify-content: space-between; 
    .c-info__row{ 
        margin-top: 15px !important
    }
    h3 {
        img{ margin-right: 10px} 
    }
    .social{
      
        margin-left: 40px;
    }
    .right{
        text-align: right;
    }
}

.slider-gamehub{
    display: flex;
    justify-content: space-between;
    .c-upload{
        width: 30%;  
        &__box {
            min-height: 200px;
            height: 200px;
        }
    }
}

.flex-address{
    display: flex;
    img{
        margin-right:8px
    }
}