.p-component {
    padding: 0 50px;
    &__box {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
        border-bottom: 1px solid #000;
    }
    &__item {
        margin: 10px 30px 10px 0;
    }
}

// Page Login
.p-login {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 32px 0;
    position: relative;
    &__left {
        width: 50%;
        height: 100%;
        background: #ECBC76;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        h1 {
            padding: 25px 0 0 45px;
            margin: 0;
        }
    }
    &__img {
        position: absolute;
        z-index: 1;
        &.is-top {
            left: 45px;
            top: 80px
        }
        &.is-bottom {
            right: 35px;
            bottom: 60px;
        }
        img {
            display: block;
        }
    }
    &__box {
        width: 540px;
        background: #FFFFFF;
        box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
        border-radius: 40px;
        padding: 48px 40px;
        position: relative;
        z-index: 2;
    }
    &__title {
        p {
            font-size: 24px;
            line-height: 36px;
            color: #000;
            margin: 0;
            span {
                color: #FFD60A;
                font-weight: bold;
            }
        }
        h3 {
            font-size: 56px;
            line-height: 84px;
            color: #000;
            padding: 0;
            margin: 8px 0 40px;
        }
    }
    &__row {
        + .p-login__row {
            margin-top: 32px;
        }
    }
    &__forgot {
        margin-top: 16px;
        margin-bottom: 48px;
        text-align: right;
        a {
            font-size: 14px;
            line-height: 21px;
            color: #AD3113;
            &:hover {
                opacity: .8;
                color: #AD3113;
            }
        }
    }
    .c-btn {
        width: 100%;
    }
}

//Page Campaign
.p-campaign {
    padding-bottom: 90px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .c-title {
            padding-right: 32px;
        }
    }
    &__tabs {
        margin-top: 40px;
    }
}

//Page Campaign Detail
.p-campaignInfo {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    > .c-title {
        width: 100%;
        margin-bottom: 50px;
    }
    &__round {
        width: 60%;
        padding-right: 30px;
    }
    &__info {
        width: 40%;
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px 5px 0px 0px;
        overflow: hidden;
    }
    &__btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 16px;
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
        }
    }
    &__title {
        margin: 0 0 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 20px;
            line-height: 20px;
            color: #4E5965;
            margin: 0;
        }
    }
}

.p-campaignEdit {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px; 
    
    &__left {
        width: 60%;
        padding-right: 30px;
        .c-title {
            margin-bottom: 32px;
        }
    }
    &__right {
        width: 40%;
        .c-title {
            margin-bottom: 8px;
            p {
                margin: 0;
            }
        }
    }
    &__row {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
        .c-select{
            width: calc(50% - 12px);
            .ant-col-16 {
                max-width: 100%;
        } 
        }
        
        &.is-wFull {
            .c-input,
            .c-select,
            .c-upload {
                width: 100%;
            }
        }
    }
    
    &__timeTable {
        margin-top: 24px;
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        padding: 24px;
        margin-top: 8px;
        .c-date {
            .ant-col-16 {
                max-width: 100%;
            }
            + .c-date {
                margin-top: 24px;
                 
            }
        }
    }
    &__btn {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .p-campaignEdit__right {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
            &--grey {
                background: transparent;
                &:hover {
                    background: #8E9BA9;
                }
            } 
        }
    }
}

.p-campaignAdd {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    
    &__left {
        width: 60%;
        padding-right: 30px;
        .c-title {
            margin-bottom: 32px;
        }
    }
    &__right {
        width: 40%;
        .c-title {
            margin-bottom: 8px;
            p {
                margin: 0;
            }
        }
    }
    &__row {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 
        .c-select{
            width: calc(50% - 12px);
            .ant-col-16 {
                max-width: 100%;
        } 
        }
        .c-upload {
            width: calc(50% - 12px);
        }
        &.is-wFull {
            .c-input,
            .c-select,
            .c-upload {
                width: 100%;
            }
        }
    }
    &__timeTable {
        margin-top: 24px;
    }
    &__box {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        padding: 24px;
        margin-top: 8px;
        .c-date {
            .ant-col-16{
                max-width: 100%;
            }
            + .c-date {
                margin-top: 24px;
            }
        }
    }
    &__btn {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .p-campaignAdd__right {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
            &--grey {
                background: transparent;
                &:hover {
                    background: #8E9BA9;
                }
            }
        }
    }
}

.p-roundEdit {
    padding: 0 0 100px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
        .c-title {
            padding-right: 32px;
        }
    }
    &__body {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        > .c-ttl {
            font-weight: 500;
            padding: 24px 30px;
            span {
                font-size: 18px;
                margin-right: 16px;
                font-weight: normal;
            }
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        > div {
            display: flex;
            align-items: center;
        }
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
            &--grey {
                background: transparent;
                &:hover {
                    background: #8E9BA9;
                }
            }
        }
    }
}

.p-roundAdd {
    padding: 0 0 100px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
        .c-title {
            padding-right: 32px;
        }
    }
    &__body {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        > .c-ttl {
            font-weight: 500;
            padding: 24px 30px;
            span {
                font-size: 18px;
                margin-right: 16px;
                font-weight: normal;
            }
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
        > div {
            display: flex;
            align-items: center;
        }
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
            &--grey {
                background: transparent;
                &:hover {
                    background: #8E9BA9;
                }
            }
        }
    }
}

//Token inform
.p-tokenInform {
    padding-bottom: 90px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .c-title {
            padding-right: 32px;
        }
    }
    &__box {
        margin-top: 40px;
    }
}

//Whitelist
.p-whitelist {
    padding-bottom: 60px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;
        .c-title {
            padding-right: 32px;
            width: calc(100% - 630px);
        }
    }
    &__time {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #4E5965;
        margin-bottom: 32px;
        span {
            display: block;
            margin: 0 10px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
            color: #F67519;
            padding: 10px 8px;
        }
    }
    &__btn {
        display: flex;
        flex-wrap: wrap;
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
        }
    }
    &__boxHead {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .c-select {
            width: 260px;
            + .c-select {
                margin-left: 24px;
            }
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 16px 0 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            li {
                width: 260px;
                background: #F8F8F8;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                padding: 32px 24px;
                font-size: 24px;
                line-height: 1;
                color: #4E5965;
                font-weight: bold;
                + li {
                    margin-left: 24px;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    color: #93A0AE;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: normal;
                    margin-bottom: 8px;
                    img {
                        display: block;
                        width: 24px;
                    }
                }
                &.is-active {
                    background: linear-gradient(180deg, #6585FD 0%, #466CF7 100%);
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                    color: #fff;
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
}

//Allocation
.p-allocation {
    padding-bottom: 20px;
    &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        .c-title {
            padding-right: 32px;
            width: calc(100% - 390px);
        }
    }
    &__boxHead {
        display: flex;
        flex-wrap: wrap;
        margin: 32px 0 16px;
        .c-select {
            width: 260px;
            + .c-select {
                margin-left: 24px;
            }
        }
    }
    &__box {
        background: #fff;
        border: 1px solid #DADADA;
    }
}

//Claim Token Schedule
.p-claimSchedule {
    padding-bottom: 50px;
    &__tabs {
        margin-top: 40px;
    }
    &__boxBtn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 24px;
        .c-btn {
            margin-left: 24px;
        }
    }
    &__head {
        list-style-type: none;
        display: flex;
        align-items: center;
        padding: 0 16px 0 71px;
        margin: 0 0 16px;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        li {
            display: block;
            width: calc(100%/6);
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            color: #697A8D;
        }
    }
}

//System Admin
.p-systemAdmin {
    padding-bottom: 90px;
    &__tabs {
        margin-top: 40px;
    }
}

//Setting
.p-setting {
    padding-bottom: 90px;
    &__head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .c-title {
            width: calc(100% - 250px);
            padding-right: 16px;
        }
        .c-btn {
            margin-right: 0;
        }
    }
    &__title {
        margin-bottom: 24px;
        h3 {
            padding: 0;
            margin: 0 0 8px;
            font-size: 20px;
            line-height: 1;
            color: #4E5965;
            font-weight: normal;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.025em;
            color: #4E5965;
            margin: 0;
        }
    }
    &__tabs {
        margin-top: 40px;
    }
    &__box {
        display: flex;
        align-items: flex-start;
        border-top: 1px solid #DADADA;
        padding: 24px 0;
        .p-setting__title {
            width: 260px;
        }
    }
    &__input,
    &__table,
    &__timetable,
    &__notifi {
        width: calc(100% - 260px);
        padding-left: 16px;
        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            + .ant-checkbox-wrapper {
                margin-left: 0;
                margin-top: 24px;
            }
            .ant-checkbox {
                top: auto;
                &:after {
                    border-radius: 4px;
                }
                + span {
                    padding: 0;
                    margin-left: 12px;
                }
                &-inner {
                    width: 24px;
                    height: 24px;
                    border: 1px solid #DADADA;
                    border-radius: 4px;
                    &::after {
                        display: none;
                    }
                }
                &-checked {
                    &:after {
                        border-radius: 4px;
                    }
                    .ant-checkbox-inner {
                        background-color: #4285F4;
                        border: 1px solid #4285F4;
                        &:before {
                            content: '';
                            width: 16px;
                            height: 16px;
                            background: url(../images/icon-checked.svg) no-repeat center;
                            background-size: 16px;
                            position: absolute;
                            top: calc(50% - 8px);
                            left: calc(50% - 8px);
                        }
                        
                    }
                    &:hover {
                        .ant-checkbox-inner {
                            background-color: #3C78DB !important;
                            border: 1px solid #3C78DB !important;
                            border-radius: 4px;
                        }
                    }
                }
            }
            &:hover {
                .ant-checkbox-inner {
                    background-color: #4285F4;
                    border: 1px solid #4285F4;
                    &:before {
                        content: '';
                        width: 16px;
                        height: 16px;
                        background: url(../images/icon-checked.svg) no-repeat center;
                        background-size: 16px;
                        position: absolute;
                        top: calc(50% - 8px);
                        left: calc(50% - 8px);
                    }
                }
            }
        }
    }
    &__inputHours {
        display: flex;
        flex-wrap: wrap;
        + .p-setting__inputHours {
            margin-top: 24px;
        }
        p {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            color: #4E5965;
            margin: 0 0 8px;
        }
        .c-input {
            width: 270px;
        }
        span {
            letter-spacing: 0.025em;
            color: #4E5965;
            font-size: 18px;
            line-height: 56px;
            padding-left: 16px;
        }
    }
    &__table {
        table {
            width: 100%;
            thead {
                th {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #4E5965;
                    text-align: center;
                    width: calc(100%/3);
                    padding-bottom: 10px;
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: #fff;
                        border-radius: 5px;
                    }
                }
                td {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    letter-spacing: 0.025em;
                    color: #4E5965;
                    width: calc(100%/3);
                    padding: 16px;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                    }
                    .ant-checkbox-wrapper {
                        width: 36px;
                        margin: 0 auto;
                        .ant-checkbox {
                            &-inner {
                                width: 36px;
                                height: 36px;
                            }
                            &-checked {
                                .ant-checkbox-inner {
                                    &:before {
                                        width: 24px;
                                        height: 24px;
                                        background-size: 24px;
                                        top: calc(50% - 12px);
                                        left: calc(50% - 12px);
                                    }                                    
                                }
                            }
                        }
                        &:hover {
                            .ant-checkbox-inner {
                                background-color: #4285F4;
                                border: 1px solid #4285F4;
                                &:before {
                                    width: 24px;
                                    height: 24px;
                                    background-size: 24px;
                                    top: calc(50% - 12px);
                                    left: calc(50% - 12px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__input {
        display: flex;
        justify-content: space-between;
        .c-input {
            width: calc(50% - 20px);
        }
    }
    .c-table {
        .c-search {
            margin-right: 0;
        }
        .c-actionTable {
            &__content {
                a {
                    color: #4E5965;
                    text-decoration: none;
                    text-align: left;
                }
            }
        }
    }
}

.p-account {
    padding-bottom: 84px;
    &__table {
        margin-top: 32px;
        &.c-table {
            table {
                background: #fff;
                thead {
                    th {
                        border-bottom: 1px solid #DADADA;
                        width: calc(100%/8);
                        padding: 16px 6px;
                        &.is-borderRight {
                            border-right: 1px solid #DADADA;
                        }
                    }
                }
                tbody {
                    tr {
                        &:nth-child(even) {
                            background: #F4F5F7;
                        }
                        &:nth-child(3n) {
                            td {
                                border-bottom: 1px solid #DADADA;
                            }
                        }
                        td {
                            border: 0;
                            width: calc(100%/8);
                            padding: 16px 6px;
                            .ant-checkbox {
                                &-inner {
                                    background-color: #FFFFFF;
                                    border: 1px solid #DADADA;
                                    border-radius: 4px;
                                    width: 36px;
                                    height: 36px;
                                    &::after {
                                        display: none;
                                    }
                                }
                                &-checked {
                                    &:after {
                                        border-radius: 4px;
                                    }
                                    .ant-checkbox-inner {
                                        background-color: #4285F4;
                                        border: 1px solid #4285F4;
                                        &:before {
                                            content: '';
                                            width: 24px;
                                            height: 24px;
                                            background: url(../images/icon-checked.svg) no-repeat center;
                                            background-size: 24px;
                                            position: absolute;
                                            top: calc(50% - 12px);
                                            left: calc(50% - 12px);
                                        }
                                        
                                    }
                                    &:hover {
                                        .ant-checkbox-inner {
                                            background-color: #3C78DB !important;
                                            border: 1px solid #3C78DB !important;
                                            border-radius: 4px;
                                        }
                                    }
                                }
                                &:hover {
                                    .ant-checkbox-inner {
                                        background-color: #4285F4;
                                        border: 1px solid #4285F4;
                                        &:before {
                                            content: '';
                                            width: 24px;
                                            height: 24px;
                                            background: url(../images/icon-checked.svg) no-repeat center;
                                            background-size: 24px;
                                            position: absolute;
                                            top: calc(50% - 12px);
                                            left: calc(50% - 12px);
                                        }
                                    }
                                }
                            }
                            &.is-borderRight {
                                border-right: 1px solid #DADADA;
                            }
                            &.is-rowSpan {
                                border-bottom: 1px solid #DADADA;
                                background: #fff;
                            }
                        }
                    }
                }
                
            }
        }
    }
}

//INO
.p-ino {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    .ant-input {
        border-radius: 10px;
        font-size: 16px;
        line-height: 24px;
    }
    .c-popup {
        &__btn {
            .c-btn {
                margin-left: unset;
                + .c-btn {
                    margin-left: 16px;
                }
            }
        }
    }
    &.is-add {
        width: 100%;
        justify-content: space-between;
        .p-ino__row {
            width: calc(100%/3 - 16px);
            .ant-form-item {
                width: 100%;
            }
            .c-ttl {
                margin-bottom: 8px;
            }
            .c-uploadIMG {
                background: #FFFFFF;
                border: 1px solid #DADADA;
                border-radius: 10px;
            }
            .c-btn {
                justify-content: flex-start;
            }
            &.is-width {
                width: calc((100%/3)*2 - 10px);
            }
            &.is-widthFull {
                width: 100%;
            }
        }
        .p-ino__box {            
            .c-date {
                margin-top: 0;
                + .c-date {
                    margin-top: 24px;
                }
            }
        }
    }
    
    .c-title {
        margin-bottom: 32px;
        width: 100%;
    }
    &__left {
        width: 60%;
        padding-right: 30px;        
    }
    &__right {
        width: 40%;
        .c-title {
            margin-bottom: 8px;
            p {
                margin: 0;
            }
        }
        .c-upload {
            &__box {
                color: #4285F4;
                font-size: 16px;
                line-height: 24px;
                svg {
                    margin-right: 5px;
                    path {
                        stroke: #4285F4;;
                    }
                }
                .c-btn {
                    margin: 0 auto;
                    width: 100%;
                }
            }            
        }
        .c-textarea {
            margin-top: 24px;
        }
        .c-btn {
            margin-left: auto;
        }
    }
    &__ttl {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        color: #4E5965;
        margin: 0 0 8px;
    }
    &__row {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .c-date {
            width: 100%;
            .ant-form-item {
                width: 100%;
                border: 1px solid #DADADA;
                padding: 15px;
                border-radius: 5px;
            }
        }
        .ant-form-item {
            width: calc(50% - 12px);
            margin-bottom: 0;
            .ant-col-16 {
                max-width: 100%;
            } 
        }
        .c-upload {
            width: calc(50% - 12px);
        }
        &.is-wFull {
            .c-input,
            .c-select,
            .c-upload {
                width: 100%;
            }
        }
    }
    &__box {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 5px;
        padding: 16px;
        .c-date {
            margin-top: 16px;
            .ant-form-item {
                width: 100%;
                border: 0;
                padding: 0;
                border-radius: 0;
            }
        }
        .c-repeater {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &__title {
                width: calc(50% - 16px);
                &:first-child {
                    padding-left: 24px;
                }
            }
            &__row {
                width: 100%;
            }
            .c-btn {
                width: auto;
                margin-top: 24px;
            }
        }
    }
    &__staking {
        margin-top: 24px;
        .ant-form-item {
            width: 100%;
        }
    }
    &__media {
        margin-top: 24px;
        .c-media {
            li {
                .c-input {
                    width: 100%;
                }
            }
        }        
    }
    &__btn {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .c-btn {
            &--grey {
                background: transparent;
                &:hover {
                    background: #8E9BA9;
                }
            }
        }
        span {
            display: flex;
            align-items: center;
            .c-btn {
                + .c-btn {
                    margin-left: 20px;
                }
            }
        }
    }
}

.p-inoInfo {
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 32px;
    }
    &__table {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        table {
            thead {
                th {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #697A8D;
                    background: #fff;
                }
            }
            tbody {
                td {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #4E5965;
                }
            }
        }
    }
}

.p-boxesList {
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 32px;
    }
    &__table {
        background: #FFFFFF;
        border: 1px solid #DADADA;
    }
}

.p-inoWhitelist {
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .c-title {
            width: calc(100% - 600px);
        }
    }
    &__btn {
        display: flex;
        justify-content: flex-end;
        width: 600px;
        .c-btn {
            + .c-btn {
                margin-left: 16px;
            }
        }
    }
    &__timeStaking {
        width: 100%;
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #4E5965;
        display: flex;
        align-items: center;
        span {
            display: block;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
            color: #F67519;
            margin: 0 8px;
            padding: 9px 8px;
        }
    }
    &__boxHead {
        margin-top: 32px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .c-select {
            width: 260px;
            + .c-select {
                margin-left: 24px;
            }
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 16px 0 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            li {
                width: 260px;
                background: #F8F8F8;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                padding: 32px 24px;
                font-size: 24px;
                line-height: 1;
                color: #4E5965;
                font-weight: bold;
                + li {
                    margin-left: 24px;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    color: #93A0AE;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: normal;
                    margin-bottom: 8px;
                    img {
                        display: block;
                        width: 24px;
                    }
                }
                &.is-active {
                    background: linear-gradient(180deg, #6585FD 0%, #466CF7 100%);
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                    color: #fff;
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
    &__table {
        margin-top: 32px;
        background: #fff;
        padding-bottom: 24px;
        border: 1px solid #DADADA;
        .c-table {
            &__head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 0;
                .c-ttl {
                    width: 200px;
                    font-size: 18px;
                }
                span {
                    display: flex;
                    justify-content: flex-end;
                    width: calc(100% - 200px);
                    .c-btn {
                        + .c-btn {
                            margin-left: 24px;
                        }
                    }
                }
            }
            table {
                width: calc(100% + 2px);
                margin: 0 -1px;
            }
            .ant-pagination {
                padding-right: 24px;
            }
        }
    }
}